/**路由 */
export default [
    {
        name:'appCenter',
        path:'appCenter',
        meta: { title: '应用中心' },
        component: ()=>import('@/pages/appCenter/index.vue')
    },
    {
        name: 'appCenter-info',
        path: 'appCenter/info',
        meta: { title: '应用中心 / 创建' },
        component: ()=>import('@/pages/appCenter/info.vue')
    },
]