export default{
    title: '速速云签',
    // path:"wss://saassystem.puhuijishu.top:9503",//测试服webscoket
    path:"wss://saassocket.hulianzaowu.com:9503",//线上webscoket    
    // apiUrl:'http://192.168.1.201',//本地
    // apiUrl:'http://saas.hulianzaowu.com',//线上
    elseUrl:'http://guanwang.puhuijishu.xyz',
    // apiUrl:'http://guanwang.puhuijishu.xyz',
    // apiUrl:'http://susuyunqian.hulianzaowu.com'
    // apiUrl:'http://saassystem.hulianzaowu.top'//测试服
    apiUrl : `${location.origin}`
}