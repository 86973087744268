import Vue from 'vue'
import App from './App.vue'
import router from './router'
import config from '@/config';
import ViewUI from 'view-design';
import store from './store'
import 'view-design/dist/styles/iview.css';
import moment from "moment";
import Meta from 'vue-meta'   

// 导入富文本编译器
import VueQuillEditor from 'vue-quill-editor'
// require styles 导入富文本编译器对应的样式
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css' 
 
// 将富文本编辑器注册为全局可用的组件
Vue.use(VueQuillEditor) 
Vue.use(Meta)
Vue.use(ViewUI);
Vue.use(moment);

Vue.config.productionTip = false
Vue.prototype.config = config
new Vue({
  store,
  router,
  render: h => h(App),
}).$mount('#app')

//提交按钮 防止多次点击  默认一秒    Button 中加 v-throttle
Vue.directive('throttle',{
  inserted(el,binding){
    el.addEventListener('click',()=>{
      el.style.pointerEvents='none';
      if(!el.disabled){
        setTimeout(()=>{
          el.style.pointerEvents='auto'
        },binding.value||1000)
      }
    })
  }
})
