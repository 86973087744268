<template>
   <div id="app" class="layout">
        <router-view/>
    </div>
</template>

<script>
 
export default {
  name: 'App',
  data(){
      return{
      }
  },
  mounted(){
  },
  methods:{

  }
}
</script>
<style>
 .layout {
   overflow: hidden!important;
 }
 .vertical-center-modal {
   display: flex;
   align-items: center;
   justify-content: center;
 }

 .vertical-center-modal .ivu-modal {
   top: 0;
 }
 /*定义滚动条高宽及背景 高宽分别对应横竖滚动条的尺寸*/
 ::-webkit-scrollbar
 {
   width: 10px;
   height: 10px;
   background-color: #F5F5F5;
 }

 /*定义滚动条轨道 内阴影+圆角*/
 ::-webkit-scrollbar-track
 {
   -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
   border-radius: 10px;
   background-color: #F5F5F5;
 }

 /*定义滑块 内阴影+圆角*/
 ::-webkit-scrollbar-thumb
 {
   border-radius: 10px;
   -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
   background-color: #555;
 }
</style>
