<template>
  <div>
    <Modal
        title="修改密码"
        v-model="showModel"
        @on-ok="submit"
        @on-cancel="cancel"
        width="600">
      <Form :model="formItem" :label-width="120" style="width: 500px">
        <FormItem label="当前密码">
          <Input v-model="formItem.oldPass" type="password" placeholder="请输入当前密码"></Input>
        </FormItem>
        <FormItem label="新密码">
          <Input v-model="formItem.newPass" type="password" placeholder="请输入新密码"></Input>
        </FormItem>
        <FormItem label="确认新密码">
          <Input v-model="formItem.newsPass" type="password"  placeholder="请确认新密码"></Input>
        </FormItem>
      </Form>
    </Modal>
  </div>
</template>

<script>
import {updatePwd} from  '../../api/operation'
export default {
  name: "password",
  data(){
    return{
      formItem:{
        oldPass:'',
        newPass:'',
        newsPass:''
      },
      showModel:false
    }
  },
  methods:{
    open(e){
      this.showModel=true
      console.log(e)
    },
    submit(){
      let _this = this
      updatePwd(this.formItem).then(res=>{
        this.$Message.success(res.msg)
        _this.$emit('layOut')
      }).catch(res=>{
        this.$Message.error(res.msg)
      })
      _this.reset()
    },
    cancel(){
      this.reset()
    },
    reset(){
      this.formItem.oldPass=''
      this.formItem.newPass=''
      this.formItem.newsPass=''
    }
  }
}
</script>

<style scoped>

</style>