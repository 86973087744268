import Vue from "vue";
import VueRouter from "vue-router";
import mainLayout from "@/components/mainLayout"
import index from "./modules/index"
import customer from "./modules/customer"
import works from "./modules/works"
import platform from "./modules/platform"
import notice from "./modules/notice"
import agent from "./modules/agent"
import operation from "./modules/operation"
import maintain from "./modules/maintain"
import appCenter from "./modules/appCenter" //应用中心
import orderCenter from "./modules/orderCenter" //订单中心


import remotecontrol from "./modules/remotecontrol"
Vue.use(VueRouter);

const router = new VueRouter({
    routes:[
        {
            name: '登录',
            path: '/',
            component: ()=>import("@/pages/login/login.vue")
        },
        {
            path:'/admin',
            meta: {title: '速速云签'},
            component: mainLayout,
            children: [
                ...index,
                customer,
                ...works,
                ...platform,
                ...notice,
                ...agent,
                ...maintain,
                ...operation,
                ...remotecontrol,
                ...appCenter,//应用中心
                ...orderCenter, //订单中心
            ]
        }
    ]
})

router.beforeEach((to, from, next) => {
    window.document.title = "速速云签sass平台";
    next();
})
export default router;
