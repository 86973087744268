<template>
  <div>
    <Modal
        title="消息公告"
        v-model="detailTable"
        width="600"
        :footer-hide="true"
        :mask-closable="false">
      <Table border :columns="detailColumns" :data="detailData">
        <template slot-scope="{ row }" slot="title">
          <div class="handler" :class="row.is_read == 0 ? 'blue' : '' " @click="seeDetail(row.id,row)">{{ row.title }}</div>
        </template>
      </Table>
      <br/>
      <Page :total="total" :current="noticePrams.page" style="text-align:right" show-elevator show-total @on-change="pageChange"
            :page-size="noticePrams.limit"/>
      <Modal
          title="消息公告详情"
          v-model="showMsg"
          width="500"
          :footer-hide="true"
          :mask-closable="false"
          class-name="vertical-center-modal">
        <div>
          <div style="font-size: 16px;font-weight: bold;margin-bottom: 10px;color: black">{{msgTitle}}</div>
          <div style="font-size: 14px">{{msgContent}}</div>
        </div>
      </Modal>
    </Modal>
  </div>
</template>

<script>
import {getAnnouncement, noticeRead} from "../../api/operation";

export default {
  name: "notice",
  data() {
    return {
      detailTable:false,
      detailColumns: [
        {
          title: '标题',
          key: 'title',
          slot:'title'
        },
        {
          title: '时间',
          key: 'created_at'
        }
      ],
      detailData: [],
      showMsg:false,
      noticePrams: {
        limit: 10,
        page: 1,
      },
      total:0,
      unread:'',
      msgTitle:'',
      msgContent:''
    }
  },
  created() {
    this.getNotice()
  },
  mounted() {

  },
  methods:{
    show(){
     this.detailTable = true
    },
    allRead(id){
      noticeRead({notice_id:id}).then(res=>{
        this.$Message.success(res.msg)
        this.getNotice()
      }).catch(res => {
        this.$Message.error(res.msg)
      })
    },
    seeDetail(id,row){
      if(row.is_read == 0){
        this.allRead(id)
      }
      this.showMsg=true
      this.msgTitle=row.title
      this.msgContent=row.synopsis
    },
    pageChange(index) {
      this.storePrams.page = index
      this.getNotice()
    },
    getNotice(){
      getAnnouncement(this.noticePrams).then(res => {
        this.detailData = res.data.data || []
        this.unread = res.data.unread_num
        this.total = res.data.total
        this.$emit('showNum',this.unread)
      }).catch(res => {
        console.log(res)
        // this.$Message.error(res)
      })
    }
  }
}
</script>

<style scoped>
.notice_card {
  padding: 12px 20px;
  width: 280px;
  margin-bottom: 20px;
  background-color: #fff;
  border: 1px solid #ebeef5;
  border-radius: 4px;
  box-shadow: 0 2px 6px 0 rgba(235, 240, 245, 0.89);
  word-break: break-all;
  position: relative;
}
.icon-style {
  position: absolute;
  top: 5px;
  right: 10px;
  cursor: pointer;
}

.handler {
  cursor: pointer;

}
.blue {
  color: #1A73E8;
}
</style>