/**用户路由 */
export default {
        name: 'customer',
        path: 'customer',
        meta: {
            title: '操作日志'
        },
        component: ()=>import('@/pages/index.vue'),
        children: [
            {
                name: 'customer-author',
                path: 'author',
                meta: { title: 'saas平台' },
                component: ()=>import('@/pages/customer/author.vue')
            },
            {
                name: 'customer-tourist',
                path: 'tourist',
                meta: { title: '电子合同' },
                component: ()=>import('@/pages/customer/tourist.vue')
            }
        ]
}
    
