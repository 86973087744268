<template>
    <Layout :style="'min-height:'+ innerHeight + 'px'">
        <Header>
            <Menu mode="horizontal" theme="dark">
                <div class="layout-logo">速速云签</div>
                <div class="layout-nav">
                    <MenuItem style="display: flex;align-items: center" name="userName">
                        <div class="bg-circle">{{ userInfo.account?userInfo.account.substring(0,1):'' }}</div>{{ userInfo.account }}
                    </MenuItem>
                    <div class="show-menu">
                        <div @click="changePassword">修改密码</div>
                        <div @click="logout">退出登录</div>
                    </div>
                    <div class="show-msg" v-if="this.userInfo.level === 2" @click="seeContent">
                        <div class="red" v-if="msgNumber >0">{{ msgNumber }}</div>
                        <Icon type="ios-notifications-outline" size="26"></Icon>
                    </div>
                </div>
            </Menu>
        </Header>
        <Layout>
            <Sider hide-trigger :style="{background: '#fff'}">
                <Menu :active-name="String($route.name)" ref="menu" theme="light" width="auto" :open-names="openNames">
                <MenuItem name="index" to="/admin" style="display: flex;align-items: center">
                    <Icon type="ios-home-outline" size="25"/>
                    首页
                </MenuItem>
                <div>
                    <!-- <div>
                    <MenuItem name="order" to="/admin/order" style="display: flex;align-items: center">
                        <Icon type="ios-home-outline" size="25"/>
                        <div >应用订单</div>
                    </MenuItem>
                    </div> -->         
                    <div v-for="(item,index) in muen" :key="index">
                        <div v-if="item.is_show_path === 0 && item.children.length>0 && item.children[0].is_show_path === 0">
                            <Submenu :name="item.unique_auth" >
                                <template slot="title" style="display: flex;align-items: center">
                                    <Icon :type="item.icon" size="25"/>
                                    {{ item.menu_name }}
                                </template>
                                <MenuItem  v-for="(child,idx) in item.children" :key="idx" :name="child.unique_auth" :to="child.menu_path">
                                    <div v-if="jurisdictionList.indexOf(child.unique_auth) !== -1">
                                    {{ child.menu_name }}
                                    </div>
                                </MenuItem>
                            </Submenu>
                        </div>
                        <div v-else>
                            <MenuItem :name="item.unique_auth" :to="item.menu_path" style="display: flex;align-items: center">
                            <Icon :type="item.icon" size="25"/>
                            <div @click="chooseMenu(index)">{{ item.menu_name }}</div>
                            </MenuItem>
                        </div>              
                    </div>
                </div>
                </Menu>
            </Sider>
            <Layout :style="{padding: '0 24px'}">
                <Breadcrumb :style="{margin: '18px 0'}">
                <BreadcrumbItem v-for="(item, index) in $route.matched" :key="index">{{ item.meta.title }}</BreadcrumbItem>
                </Breadcrumb>
                <Content :style="{padding: '24px', minHeight: '280px', background: '#fff'}">
                <router-view></router-view>
                </Content>
                <div style="text-align: center;margin-top: 5px">Copyright  2020 安徽互联造物云计算有限公司</div>
            </Layout>
        </Layout>
        <password ref="Password" @layOut="layOut"></password>
        <div class="right-show">
            <notice ref="notice" @showNum="numberShow"></notice>
        </div>
        <div v-if='isPolling' ><loadings v-if="isGlobalSpin" ></loadings></div>
    </Layout>
</template>
<script>
import {windowsRise} from '@/utils/dom';
import {removeCookies} from '@/utils/helper'
import password from '../changePassword/password'
import notice from '../noticeMessage/notice'
import vm from '@/utils/util.js'
// import config from '@/config/index'
import loadings from '@/components/loadings/loadings'
// var that;
export default {
    data() {
        return {
            innerHeight: 0,
            contnes: 0,
            openNames: [],
            noticeList: [],
            jurisdictionList: [],
            msgNumber: '',
            //scoket 唐
            wsData: [], // 保存 websocket 数据对象
            form: {
                // 表单
                name: '',
                age: ''
            },
            path: "",
            socket: "",
            timer: "",
            tims: "",
        }
    },
    metaInfo: {
		title:'首页',
		meta: [
			{ name: 'viewport', content: 'width=device-width,user-scalable=yes,initial-scale=0.3,minimum-scale=0.3,maximum-scale=2' }
		]
	},
    components:{
        password,
        notice,
        loadings
    },
    computed: {
        muen(){
            return this.$store.state.menus
        },
        userInfo(){
            return this.$store.state.userInfo
        },
        isGlobalSpin(){
            return this.$store.state.isGlobalSpin
        },
        isPolling(){
            return this.$store.state.isPolling
        }
    },
    created() {
        //scoket  唐
        // that = this;s
        // let uid = this.$store.state.userInfo.id
        // this.path = `${config.path}?uid=${uid}`  
    },
    destroyed () {
        // 销毁监听
        // clearInterval(this.timer)
        // clearInterval(this.tims)
        // this.socket.onclose = this.close    
    },
    mounted() {
        // this.init() //唐 调用
        this.jurisdictionList = this.$store.state.auth
        this.restHeight();
        windowsRise(window, this.restHeight);
        this.selectMenu();
        if(this.userInfo.level === 2 && this.userInfo.is_warning === true){
            this.$Notice.open({
                title: '预警提示',
                desc: '您的短信通知条数已不足100条，为保证使用正常，请及时调整。',
                duration: 0
            });
        }
    },
    watch: {
        '$route.path'() {
        this.selectMenu();
        }
    },
    methods: {        
        numberShow(e){
            this.msgNumber = e
        },
        seeContent(){
            this.$refs.notice.show()
        },
        chooseMenu(e){
            console.log(this.muen[e])
        },
        changePassword(){
            this.$refs.Password.open('123')
        },
        layOut(){
            this.logout()
        },
        /**修改高度 */
        restHeight() {
            this.innerHeight = window.innerHeight - 2;
        },
        /**修改导航展开样式 */
        selectMenu() {
            this.openNames = [this.$route.name.split('-')[0]];
            this.$nextTick(() => {
                this.$refs.menu.updateOpened();
            })
        },
        logout() {
            this.$store.dispatch("resetAction");
            this.$store.dispatch("resetMen");
            removeCookies('token')
            this.$router.replace({path: '/'})
        },
        //webscoket 
        //scocket
        // init: function () {
        //     if(typeof(WebSocket) === "undefined"){
        //         alert("您的浏览器不支持socket")
        //     }else{
        //         // this.path = "wss://canyin.hulianzaowu.top:6001"
        //         // 实例化socket
        //         this.socket = new WebSocket(this.path)
        //         // 监听socket连接
        //         this.socket.onopen = this.open
        //         // 监听socket错误信息
        //         this.socket.onerror = this.error
        //         // 监听socket消息
        //         this.socket.onmessage = this.getMessage
        //         // socket关闭
        //         this.socket.onclose = this.close
        //     }
        // },
        open: function () {
            console.log("socket连接成功")      
            var that = this
            clearInterval(that.timer); //清除延迟执行 
            clearInterval(that.tims);
        },
        error: function () {
            console.log("连接错误")
            // this.init()
            // this.tims = setInterval(() => {
            //   this.init()
            // },5000)
        },
        getMessage: function (msg) {
            if(msg.data!=undefined){
                // let a  = JSON.stringify(JSON.parse(msg.data))
                let a  = msg.data
                if(a){
                    if(a!='打开swoole服务成功！'){
                        let s = JSON.parse(a)
                        vm.$emit('demo', s);
                    }
                }      
            }
        },
        send: function () {
            this.socket.send('HeartBeat')
        },
        close: function () {
            console.log("socket已经关闭")
            this.timer = setInterval(() => {
            //  this.init()
                this.contnes += 1 
                console.log('定时任务',this.contnes,'次',this.timer)
                // this.socket = new WebSocket(this.path)
                // this.socket.onopen = this.open
                this.init()
            },5000)
        },
    }
}
</script>

<style scoped>
.layout {
    border: 1px solid #d7dde4;
    background: #f5f7f9;
    position: relative;
    border-radius: 4px;
    overflow: hidden;
}

.layout-logo {
    width: 150px;
    height: 30px;
    background: #5b6270;
    border-radius: 3px;
    float: left;
    position: relative;
    top: 15px;
    left: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
}

.layout-nav {
    width: 200px;
    margin: 0 auto;
    margin-right: 20px;
    position: relative;
}
.layout-nav:hover .show-menu {
    display: inline-block;
}
.show-msg {
    position: absolute;
    top: 5px;
    left: 32px;
    z-index: 999999999;
    color: #F5F5F5;
    cursor: pointer;
}
.show-msg .red {
    width: 18px;
    height: 18px;
    border-radius: 50%;
    background: red;
    position: absolute;
    top: 2px;
    right: -10px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-size: 12px;
}
.show-menu {
    display: none;
    position: absolute;
    width: 100px;
    height: 120px;
    right: 0;
    top: 60px;
    background-color: #fff;
    border: 1px solid #ebeef5;
    border-radius: 4px;
    box-shadow: 0 2px 12px 0 #ebeef5;
    text-align: center;
    z-index: 99999999;
}
.show-menu div {
    cursor: pointer;
}
.show-menu div:hover{
    background: #d7dde4;
}

.ivu-menu-horizontal .ivu-menu-item {
    float: right !important;
}
.bg-circle {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: dodgerblue;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
    font-size: 24px;
}
.bottom-fixed {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 0;
}
.right-show {
    position: absolute;
    right: 20px;
    top: 180px;
    z-index: 9999;
    height: 80vh;
    overflow: auto;
}
::-webkit-scrollbar {
    width: 0px;
    height: 0px;
}
</style>
