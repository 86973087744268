export default [
    {
        name: 'platform',
        path: 'platform',
        meta: { title: '平台管理' },
        component: ()=>import('@/pages/platform/index.vue')
    }
    ,
    {
        name: 'admin-store-storeCategory-index',
        path: 'platform',
        meta: { title: '修改平台' },
        component: ()=>import('@/pages/platform/index.vue')
    }
    ,
    {
        name: 'product-product-save',
        path: 'platform',
        meta: { title: '创建平台' },
        component: ()=>import('@/pages/platform/index.vue')
    }
    ,
    {
        name: 'platform-delete',
        path: 'platform',
        meta: { title: '删除' },
        component: ()=>import('@/pages/platform/index.vue')
    }
    ,
    {
        name: 'platform-configure',
        path: 'platform',
        meta: { title: '配置开关' },
        component: ()=>import('@/pages/platform/index.vue')
    }
]